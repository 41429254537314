import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import BigHeaderVideo from "../../../components/header/bigHeader/bigHeaderVideo"
import "../../../components/layout/layout.css"
import "../../../components/devicon/devicon.css"
import logo from "../../../../static/logo-bje.svg"
import Kontakt from "../../../components/kontakt"
import Navigation from '../../../components/projekte/navigation'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import ThumbleisteSwiper from "../../../components/thumbleisteSwiper"
import Liste from "../../../components/liste"
import ListenModule from "../../../components/listenModule"
import myLocalVid from '../../../images/projekte/bje-excellence-portal/bje-excellence.mp4'
import * as projekteStyles from "../projekte.module.css"
import Specs from "../../../components/specs"


const Excellence = function (props, ref) {

    const data = useStaticQuery(graphql`
        query ExcellenceQuery {
            heropic: file(relativePath: { eq: "images/projekte/bje-excellence-portal/thumbleiste/bje-excellence-1.jpg" }) {
                childImageSharp {
                    gatsbyImageData(          
                      placeholder: BLURRED,
                      layout: FULL_WIDTH,
                      aspectRatio: 1.7
                    )
                  }
            }

            ogimage: file(relativePath: { eq: "images/projekte/bje-excellence-portal/thumbleiste/bje-excellence-1.jpg" }) {
                childImageSharp {
                    fixed(height: 400) {
                        src
                        height
                        width
                    }
                }
            }

            allFile(filter: {extension: {regex: "/(jpg|png)/"}, relativeDirectory: {eq: "images/projekte/bje-excellence-portal/thumbleiste"}} sort: {fields: name}) {
                edges {
                  node {
                    id
                    childImageSharp {
                        picscropped:gatsbyImageData(placeholder: DOMINANT_COLOR, aspectRatio: 1, layout: FULL_WIDTH, formats: [WEBP], transformOptions: {rotate: 0, cropFocus: CENTER})
                        pics:gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH, formats: [WEBP])
                      }
                  }
                }
              }                                     
        }
    `)

    const specsData = [
        { title: "Eckdaten", objects: [logo, "Marketing-Portal", "http://busch-jaeger-excellence.de"] },
        { title: "Leistungen", objects: ["konzept", "design", "programmierung", "produktion"] },
        {
            title: "Toolchain",
            objects: ["sketch", "html", "css", "js", "wordpress"],
        },
    ]

    return (
        <Layout>
            <SEO
                title="Busch-Jaeger Excellence"
                description="Q:marketing realisiert B2B Programm für Busch-Jaeger."
                image={data?.ogimage?.childImageSharp?.fixed}
            />

            <BigHeaderVideo mode="textFirst" className={`${projekteStyles.child}`} bgVideo={myLocalVid} videoMode="local" posterImage="">
                <div className="row py-5 py-md-0">
                    <div className="col-12 col-md-5">
                        <img src={logo} className="h-40px" />
                        <h1>Excellence Portal</h1>
                        <p className="introtext">Innovatives Vertriebspartnerportal mit aktiver Vertriebs- und Marketingunterstützung für Elektroinstallateure und Großhandel.</p>
                        <AnchorLink to="/projekte/b2b-platforms/busch-jaeger-excellence/#thumbleiste" title="↓ Mehr Erfahren" className="q-btn q-btn-primary" />
                    </div>
                    <div className="col-7"></div>
                </div>
            </BigHeaderVideo>

            {/* Bilderstrecke Thumbs */}
            <ThumbleisteSwiper picdata={data.allFile} bgCol="#EFEFE6" />


            {/* Reasons why */}

            <ListenModule headline="Reasons why">
                <Liste
                    items={[
                        "Portal zur Unterstützung von Marketing- und Vertriebsmaßnahmen für Marktpartner aus dem Elektrohandwerk.",
                        "Die Plattform liefert eine Vielzahl an fertigen Vorlagen für die unterschiedlichsten Werbemittel, die der Marktpartner im vorgegebenen Rahmen nach seinen eigenen Wünschen individualisieren kann.",
                        "Die hohe Akzeptanz und Nutzung durch die Marktpartner führt zu einer Entlastung der Mitarbeiter und gleichzeitig professionellen Kundenbetreuung",
                        "Einheitlicher Marktauftritt durch Einhaltung des Corporate Designs."
                    ]}
                />
            </ListenModule>

            <Specs data={specsData} />

            {/* kontakt */}
            <Kontakt ansprechpartner="juergen-dworak" />

            {/* navigation */}
            <Navigation location={props.location} />

        </Layout>
    )
}

export default Excellence